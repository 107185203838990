

@media all and (max-width: 1230px) {
  .header {
    &-right {
      margin-right: 0;
    }
  }
}


@media all and (max-width: 1060px) {
  .header {
    .logo-name-and-slogan {
      margin-left: 0;
    }
  }

  .layout-3col {
    padding-bottom: 30px;
    margin: 0 !important;
  }
}


@media all and (max-width: 999px) {


  /**
   * Homepage main slider
   */

  .flexslider-diaporama {

    .flexslider {

      .flex-caption {
        //position: absolute;
        //top: 0;
        //left: 0;
        //width: auto;
        //background: rgba(35, 96, 143, 0.9);
        //padding: 13px 19px 16px;
      }

      .flex-control-nav {
        bottom: 0;
      }

      .flex-pauseplay {
        bottom: -3px;
      }

    }

  }

  /**
   * Main menu
   */
  .secondary-menu-wrapper {
    //display: none;
  }


  /**
   * Header panels
   */

  .region-header-panels {

    margin-top: 10px;
    z-index: initial;

    .block {

      display: block;
      width: initial;
      margin-right: 0;

    }

  }

  .region-footer {
    .block {
      width: 25%;
    }
  }

  .region-bottom {
    min-height: 40px;
    height: initial;
    text-align: center;

    #block-block-3 {
      margin: initial;

      ul {

        li {
          margin: 0 5px;
          display: inline-block;

          &:first-child {
            float: initial;
          }
        }
      }

    }
  }
}

@media all and (max-width: 979px) {
  .tb-megamenu .nav-collapse {
    background: #fff;
    width: auto;
    right: 0;

    a,
    a:hover {
      border-bottom: 1px solid #222 !important;
      border-right: none !important;
      font-weight: inherit !important;
      color: black;
    }

    .dropdown-menu {
      background: white;
    }

  }

  .secondary-menu-wrapper {
    display: none;
  }


}

@media all and (max-width: 767px) {


  .logo-name-and-slogan {

    text-align: center;

    .header__logo {
      float: none;
    }
  }

  /**
   * Homepage main slider
   */

  .flexslider-diaporama {

    .flexslider {

      .flex-control-nav,
      .flex-pauseplay {
        display: none;
      }

      .flex-caption {
        top: initial;
        bottom: 0;
        left: 0;
        width: auto;
      }

    }

  }

  .region-footer {
    .block {
      width: initial;
      display: block;
      margin: 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.5);

      // Map block with postal address
      &#block-block-2 {
        img {
          width: 100%;
        }
      }

    }
  }

}

@media all and (max-width: 600px) {


  /**
   * Homepage main slider
   */

  .flexslider-diaporama {

    .flexslider {

      .flex-caption {
        display: none;
      }

    }

  }

}


