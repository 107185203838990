
/**
 * Homepage main slider
 */

.flexslider-diaporama {

  .flexslider {

    margin: 0;
    border: none;

    .flex-caption {
      position: absolute;
      top: 32%;
      left: 10%;
      width: 338px;
      background: rgba(35, 96, 143, 0.9);
      padding: 13px 19px 16px;
      color: #fff;

      p:last-child {
        margin-bottom: 0;
      }

      p:first-child {
        margin-top: 0;
      }
    }

    .flex-direction-nav {
      a::before {
        line-height: 40px;
      }
    }

    .flex-control-nav {
      bottom: 73px;
      width: auto;
      right: 208px;
      z-index: 2;
    }

    .flex-pauseplay {
      position: absolute;
      right: 160px;
      bottom: 70px;

      & a::before {
        color: #fff;
      }
    }

    .flex-control-paging li a {

      background: #1c5b8c;

      &:not(.flex-active) {
        background: #fff;
      }
    }

  }

}

/**
 * Homepage news slider
 */

.region-header-panels {

  .block {

    .flexslider-news,
    .flexslider-publications {

      .flexslider {
        margin: 0;
        padding: 0;
        border: 0;
        border-radius: 0;
        background: none;

        .field-content {
          p:first-child {
            margin-top: 0;
          }
          p:last-child {
            margin-bottom: 0;
          }
        }

        .flex-direction-nav {

          a {
            top: -30px;
            display: block;
            opacity: 0.5;
            width: 15px;

            &:hover {
              opacity: 1;
            }

            &::before {
              line-height: 40px;
              font-size: 20px;

              font-family: "flexslider-icon";
              display: inline-block;
              content: '\f001';
              color: rgba(0, 0, 0, 0.8);
              text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.3);
            }
          }

          .flex-prev {
            left: initial;
            right: 15px;
          }

          .flex-next {
            right: 0;

            &::before {
              content: '\f002';
            }
          }

        }

      }
    }
  }
}
