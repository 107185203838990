
.region-header-panels,
.layout-3col__right-sidebar {

  //background: rgba(0, 0, 0, 0.34);
  margin-top: -52px;
  position: relative;
  z-index: 2;
  text-align: center;

  .block {

    &.last {
      margin-right: 0;
    }

    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    width: 270px;
    display: inline-block;
    margin-right: 26px;
    vertical-align: top;
    height: 140px;
    text-align: initial;
    background: #f9f9f9;
    padding: 11px 15px 15px 15px;
    -webkit-box-shadow: 0px 3px 3px 0px rgba(50, 50, 50, 0.55);
    -moz-box-shadow: 0px 3px 3px 0px rgba(50, 50, 50, 0.55);
    box-shadow: 0px 3px 3px 0px rgba(50, 50, 50, 0.55);
    -ms-box-shadow: 0px 3px 3px 0px rgba(50, 50, 50, 0.55);
    color: #666666;
    position: relative;
    margin-bottom: 30px;

    &#block-menu-menu-liens-vedettes {
      ul.menu {

        margin: 0;
        padding: 0;

        li.menu__item {
          line-height: 2.25rem;
          margin-bottom: 1.5em !important;
        }
      }
    }

    .block__title,
    &#block-menu-menu-liens-vedettes ul.menu li.menu__item {
      margin: 0 0 10px;
      padding: 0 0 10px;
      text-transform: uppercase;
      position: relative;
      display: block;

      font-size: 16px;
      font-weight: normal;
      color: #1c5b8c;
      font-family: 'Open Sans', sans-serif;

      a {
        text-transform: uppercase;
        text-decoration: none;
        color: #1c5b8c;
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        max-width: 47px;
        height: 2px;
        background-color: #1c5b8c;
      }

    }

  }
}