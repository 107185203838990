// The height of the navigation container.
//$layout-swap-height: 2 * $base-line-height;
$layout-swap-height: 0;

// Layout swap
//
// Uses absolute positioning and selective padding to make 2 elements appear to
// swap places in the visual order while maintaining their original HTML source
// order.
//
// Markup: layout-swap.twig
//
// Style guide: layouts.layout-swap

.layout-swap,
%layout-swap {
  position: relative;

  @include respond-to(xs) {
    // Move all the children down to make room for the "top" element.
    padding-top: $layout-swap-height;
  }

}
