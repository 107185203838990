
.logged-in {
  .tabs {
    margin-bottom: 1em;
  }
}

.page-title{

  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  background: #1c5b8c;
  color: #ffffff;
  padding: 9px 13px;
  text-transform: uppercase;
  margin-bottom: 25px;
  line-height: 1.607em;

  .front & {
    text-align: center;
    font-weight: normal;
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    background: #1c5b8c;
    color: #ffffff;
    padding: 9px 13px;
    text-transform: uppercase;
    margin-bottom: 25px;
  }

}

.layout-3col {
  padding-bottom: 30px;
  margin: 0 173px;
}

.view-id-news.news {

  .view-content {
    background: #f9f9f9;
    padding: 15px;
    border: 1px #cccccc solid;
    margin-bottom: 30px;
    color: #666666;
  }

  .pager {
    text-align: initial;
  }
}

.layout-3col__right-sidebar {

  margin-top: 3.4em;
  position: initial;
  text-align: initial;

  .block {
    margin-right: 0;
  }
}

.listing-publications {

  .view-content {

    .views-row {
      display: table;
      width: 100%;
      background: #f9f9f9;
      padding: 15px;
      border: 1px #cccccc solid;
      margin-bottom: 30px;
      color: #666666;

      .views-fieldset {
        display: table-cell;
        vertical-align: top;

        &.left {
          width: 100px;
          overflow-x: hidden;

          img {
            display: block;
          }
        }

        &.right {
          border-left: 15px solid transparent;

          .views-field-title-field a {
            font-size: 18px;
            font-family: $opensans;
            color: #1c5b8c;
            text-decoration: none;
          }

          .views-field-body {
            margin-top: 0.5em;
          }

          .views-field-view-node {
            text-align: right;

            a {
              position: relative;
              font-size: 12px;
              background: #1C5B8C;
              color: #FFF;
              padding: 0 10px;
              line-height: 1.7;
              display: block;
              margin-right: 21px;
              float: right;
              margin-top: 15px;
              text-decoration: none;
            }
          }
        }

      }

    }

  }

}

.node-type-publication {
  .view-publication-files {
    .view-content {
      .item-list{

        ul {
          margin: 0;
          padding: 0;
        }

        li {
          list-style: none;
        }

        .views-row {
          background: #f9f9f9;
          padding: 15px;
          border: 1px #cccccc solid;
          margin-bottom: 30px;
          color: #666666;

          .views-field-filename-field-et {
            a {
              text-decoration: none;
            }
          }

          .views-field-download {

            text-align: right;

            a {
              position: relative;
              text-decoration: none;
              font-size: 12px;
              background: #1C5B8C;
              color: #FFF;
              padding: 0 10px;
              line-height: 1.7;
              display: inline-block;
              margin: 15px 21px 0 0;

              &:after {
                content: '';
                position: absolute;
                right: -21px;
                background: url('../images/icon-download.png') no-repeat right;
                top: 0;
                bottom: 0;
                display: inline-block;
                height: 20px;
                width: 20px;
              }
            }
          }

        }
      }
    }
  }
}

.page-contact {
  form.contact-form {
    background: #f9f9f9;
    padding: 15px 30px;
    margin-bottom: 30px;
    position: relative;
    border: 1px #ccc solid;
  }
}

#block-block-4 {
  .content {
    font-size: 0;
    height: 200px;
    background: url('../images/banners/istock-451567141.jpg') no-repeat center center;
  }

  body.prestataires & {
    .content {
      background-image: url("../images/banners/istock-125183558.jpg");
    }
  }
  body.publications & {
    .content {
      background-image: url("../images/banners/istock-503489106.jpg");
      background-position-y: top;
    }
  }
  body.contact & {
    .content {
      background-image: url("../images/banners/istock-505620793.jpg");
    }
  }
  body.philosophie & {
    .content {
      background-image: url("../images/banners/istock-505620793.jpg");
    }
  }
  body.offre & {
    .content {
      background-image: url("../images/banners/istock-125183558.jpg");
    }
  }
  body.fonds & {
    .content {
      background-image: url("../images/banners/istock-451567141.jpg");
      background-position: center top;
    }
  }
}

#block-block-6 {
  margin-top: 2em;
}

.tb-megamenu {

  float: right;
  background-color: transparent !important;

  .nav {

    & > li.dropdown.active,
    & li.dropdown.open.active,
    & > .active,
    & > li {

      border: none;
      border-right: 1px solid #ddd;

      &.last {

        border-right: none;

        a {
          padding-right: 0;
          border-right: none;
        }
      }

      & > a,
      & > a:hover,
      & > a.active,
      & > .dropdown-toggle {
        border: none;
        font-weight: normal;
        color: #000 !important;
        text-decoration: none;
        background-color: transparent;
      }

      & > a:hover {
        color: #1c5b8c;
      }
    }

    li.dropdown.active > .dropdown-toggle {
      background-color: #ffffff;
      border-color: transparent;
      //color: #eee;
    }
  }

  .dropdown-menu{
    background: rgba(241, 243, 245, 0.8);
    border-bottom: 3px solid #1c5b8c;

    a {
      text-decoration: none;
      border: none;
      font-weight: normal;
      color: #000000 !important;
      text-decoration: none;
      background: none;
      background-color: transparent !important;
      background-image: none !important;
      color: #000;

      &:hover {
        color: #1c5b8c !important;
      }
    }
  }
}

main.layout-3col__full {
  .card-layout {

    .view-content {
      .views-row {
        padding: 20px;
        clear: both;
        border: 1px solid #ccc;
        background-color: #f9f9f9;
        margin-bottom: 1em;
      }

    }

    .views-field-field-apercu {
      width: 100px;
      height: 100px;
      float: left;
      margin-right: 2em;
    }

    .views-field-title {

      margin-bottom: 0.5em;

      a {
        font-size: 18px;
        text-decoration: none;
        text-transform: uppercase;
      }
    }
  }
}