/*
@font-face {
  font-family: 'Open Sans Light';
  src: url('fonts/Open_Sans/OpenSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans Light Italic';
  src: url('fonts/Open_Sans/OpenSans-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}*/
@font-face {
  font-family: 'Open Sans';
  src: url("fonts/Open_Sans/OpenSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans Italic';
  src: url("fonts/Open_Sans/OpenSans-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

/*
@font-face {
  font-family: 'Open Sans Semi Bold';
  src: url('fonts/Open_Sans/OpenSans-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans Semi Bold Italic';
  src: url('fonts/Open_Sans/OpenSans-SemiboldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans Bold';
  src: url('fonts/Open_Sans/OpenSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans Bold Italic';
  src: url('fonts/Open_Sans/OpenSans-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans Extra Bold';
  src: url('fonts/Open_Sans/OpenSans-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans Extra Bold Italic';
  src: url('fonts/Open_Sans/OpenSans-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}
*/
/* line 4, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/base/_root.scss */
* {
  box-sizing: border-box;
}

/* line 8, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/base/_root.scss */
html {
  font-size: 87.5%;
  line-height: 1.42857em;
  font-family: Arial, "Helvetica Neue", Helvetica, "Nimbus Sans L", sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  min-height: 100%;
}

@media print {
  /* line 8, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/base/_root.scss */
  html {
    font-size: 12pt;
  }
}

/* line 20, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/base/_root.scss */
body {
  margin: 0;
  padding: 0;
  color: #000;
  background-color: #fff;
}

/* line 7, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/base/_html5.scss */
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}

/* line 23, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/base/_html5.scss */
audio,
canvas,
progress,
video {
  display: inline-block;
}

/* line 31, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/base/_html5.scss */
audio:not([controls]) {
  display: none;
  height: 0;
}

/* line 37, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/base/_html5.scss */
progress {
  vertical-align: baseline;
}

/* line 42, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/base/_html5.scss */
template,
[hidden] {
  display: none;
}

/* line 18, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/base/links/_links.scss */
a {
  color: #0072b9;
  -webkit-text-decoration-skip: objects;
}

/* line 28, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/base/links/_links.scss */
:visited {
  color: #003353;
}

/* line 37, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/base/links/_links.scss */
a:active {
  color: #c00;
}

/* line 40, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/base/links/_links.scss */
a:active:not(.button) {
  background-color: transparent;
}

/* line 48, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/base/links/_links.scss */
a:active,
a:hover {
  outline-width: 0;
}

@media print {
  /* line 55, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/base/links/_links.scss */
  :link,
  :visited {
    text-decoration: underline;
  }
  /* line 61, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/base/links/_links.scss */
  a[href]:after {
    content: " (" attr(href) ")";
    font-weight: normal;
    font-size: 14px;
    text-decoration: none;
  }
  /* line 69, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/base/links/_links.scss */
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: '';
  }
}

/* line 13, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/base/headings/_headings.scss */
h1,
.header__site-name {
  font-size: 2.28571rem;
  line-height: 2.85714rem;
  margin-top: 1.42857rem;
  margin-bottom: 1.42857rem;
}

/* line 23, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/base/headings/_headings.scss */
h2 {
  font-size: 1rem;
  line-height: 2.14286rem;
  margin-top: 0;
  margin-bottom: 0.89286rem;
  text-transform: uppercase;
  font-weight: normal;
}

/* line 32, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/base/headings/_headings.scss */
h3 {
  font-size: 1.42857rem;
  line-height: 2.14286rem;
  margin-top: 1.42857rem;
  margin-bottom: 1.42857rem;
}

/* line 39, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/base/headings/_headings.scss */
h4 {
  font-size: 1rem;
  line-height: 1.42857rem;
  margin-top: 1.42857rem;
  margin-bottom: 1.42857rem;
}

/* line 46, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/base/headings/_headings.scss */
h5 {
  font-size: 1rem;
  line-height: 1.42857rem;
  margin-top: 1.42857rem;
  margin-bottom: 1.42857rem;
}

/* line 53, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/base/headings/_headings.scss */
h6 {
  font-size: 0.71429rem;
  line-height: 1.42857rem;
  margin-top: 1.42857rem;
  margin-bottom: 1.42857rem;
}

/* line 18, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/base/text/_text.scss */
abbr[title] {
  border-bottom: 0;
  text-decoration: underline;
  text-decoration: underline dotted;
}

@media print {
  /* line 27, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/base/text/_text.scss */
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
}

/* line 49, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/base/text/_text.scss */
b,
strong {
  font-weight: inherit;
}

/* line 55, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/base/text/_text.scss */
strong,
b {
  font-weight: bolder;
}

/* line 85, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/base/text/_text.scss */
pre,
code,
kbd,
samp,
var {
  font-family: Menlo, "DejaVu Sans Mono", "Ubuntu Mono", Courier, "Courier New", monospace, sans-serif;
  font-size: 1em;
}

/* line 117, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/base/text/_text.scss */
dfn {
  font-style: italic;
}

/* line 162, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/base/text/_text.scss */
mark {
  background-color: #fd0;
  color: #000;
}

/* line 202, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/base/text/_text.scss */
small {
  font-size: 1rem;
}

/* line 216, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/base/text/_text.scss */
sub,
sup {
  font-size: 0.71429rem;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

/* line 226, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/base/text/_text.scss */
sub {
  bottom: -.25em;
}

/* line 230, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/base/text/_text.scss */
sup {
  top: -.5em;
}

/* line 9, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/divider/_divider.scss */
.divider,
hr {
  margin: 1.42857rem 0;
  border: 0;
  border-top: 1px solid #cccccc;
}

/* line 16, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/divider/_divider.scss */
.divider > :first-child, hr > :first-child {
  margin-top: 1.42857rem;
}

/* line 26, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/base/grouping/_grouping.scss */
blockquote {
  margin: 1.42857rem 2rem;
}

/* line 66, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/base/grouping/_grouping.scss */
dl,
menu,
ol,
ul {
  margin: 1.42857rem 0;
}

/* line 77, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/base/grouping/_grouping.scss */
ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
}

/* line 87, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/base/grouping/_grouping.scss */
dd {
  margin: 0 0 0 28px;
}

/* line 18, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/init/rtl/_rtl.scss */
[dir="rtl"] dd {
  margin: 0 28px 0 0;
}

/* line 96, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/base/grouping/_grouping.scss */
menu,
ol,
ul {
  padding: 0 0 0 28px;
}

/* line 18, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/init/rtl/_rtl.scss */
[dir="rtl"] menu, [dir="rtl"]
ol, [dir="rtl"]
ul {
  padding: 0 28px 0 0;
}

/* line 127, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/base/grouping/_grouping.scss */
figure {
  margin: 1.42857rem 0;
}

/* line 146, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/base/grouping/_grouping.scss */
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

/* line 167, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/base/grouping/_grouping.scss */
p,
pre {
  margin: 0;
  margin-bottom: 0.89286rem;
}

/* line 15, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/base/embedded/_embedded.scss */
img {
  border-style: none;
}

/* line 25, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/base/embedded/_embedded.scss */
img,
svg {
  max-width: 100%;
  height: auto;
}

/* line 43, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/base/embedded/_embedded.scss */
svg:not(:root) {
  overflow: hidden;
}

/* line 20, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/base/forms/_forms.scss */
button,
input,
optgroup,
select,
textarea {
  box-sizing: border-box;
  max-width: 100%;
  font: inherit;
  margin: 0;
}

/* line 41, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/base/forms/_forms.scss */
button {
  overflow: visible;
}

/* line 46, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/base/forms/_forms.scss */
button,
select {
  text-transform: none;
}

/* line 52, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/base/forms/_forms.scss */
input {
  overflow: visible;
}

/* line 61, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/base/forms/_forms.scss */
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

/* line 66, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/base/forms/_forms.scss */
[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/* line 73, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/base/forms/_forms.scss */
[type='search']::-webkit-search-cancel-button, [type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

/* line 80, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/base/forms/_forms.scss */
::-webkit-input-placeholder {
  color: inherit;
  opacity: .54;
}

/* line 85, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/base/forms/_forms.scss */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

/* line 18, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/forms/button/_button.scss */
.button,
button,
html [type='button'],
[type='reset'],
[type='submit'] {
  -moz-appearance: button;
  -webkit-appearance: button;
}

/* line 29, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/forms/button/_button.scss */
.button,
button,
[type='button'],
[type='reset'],
[type='submit'] {
  display: inline-block;
  padding: 2px 6px;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  color: #000;
}

/* line 49, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/forms/button/_button.scss */
.button::-moz-focus-inner,
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/* line 55, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/forms/button/_button.scss */
.button:-moz-focusring,
button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/* line 59, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/forms/button/_button.scss */
.button:hover, .button:focus, .button:active,
button:hover,
button:focus,
button:active,
[type='button']:hover,
[type='button']:focus,
[type='button']:active,
[type='reset']:hover,
[type='reset']:focus,
[type='reset']:active,
[type='submit']:hover,
[type='submit']:focus,
[type='submit']:active {
  text-decoration: none;
  color: #000;
}

/* line 78, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/forms/button/_button.scss */
[disabled].button,
button[disabled],
[disabled][type='button'],
[disabled][type='reset'],
[disabled][type='submit'] {
  cursor: default;
  color: #999999;
}

/* line 83, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/forms/button/_button.scss */
[disabled].button:hover,
button[disabled]:hover,
[disabled][type='button']:hover,
[disabled][type='reset']:hover,
[disabled][type='submit']:hover, [disabled].button:focus,
button[disabled]:focus,
[disabled][type='button']:focus,
[disabled][type='reset']:focus,
[disabled][type='submit']:focus, [disabled].button:active,
button[disabled]:active,
[disabled][type='button']:active,
[disabled][type='reset']:active,
[disabled][type='submit']:active {
  color: #999999;
}

/* line 174, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/base/forms/_forms.scss */
[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}

/* line 197, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/base/forms/_forms.scss */
fieldset {
  padding: 0.5rem 0.89286rem 0.92857rem;
  border: 1px solid #cccccc;
  margin: 0 2px;
}

/* line 204, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/base/forms/_forms.scss */
legend {
  box-sizing: border-box;
  display: table;
  max-width: 100%;
  white-space: normal;
  color: inherit;
  margin-left: -5px;
  padding: 0 5px;
}

/* line 232, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/base/forms/_forms.scss */
label {
  display: block;
  font-weight: bold;
}

/* line 254, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/base/forms/_forms.scss */
optgroup {
  font-weight: bold;
}

/* line 267, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/base/forms/_forms.scss */
textarea {
  overflow: auto;
}

/* line 11, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/base/tables/_tables.scss */
table {
  margin: 1.42857rem 0;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

/* line 21, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/base/tables/_tables.scss */
td,
th {
  padding: 0;
}

/* line 50, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/layouts/layout-3col/_layout-3col.scss */
.layout-3col {
  margin-left: -20px;
  margin-right: -20px;
  padding-left: 0;
  padding-right: 0;
}

/* line 97, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/node_modules/zen-grids/sass/zen-grids/_grids.scss */
.layout-3col:before {
  content: "";
  display: table;
}

/* line 101, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/node_modules/zen-grids/sass/zen-grids/_grids.scss */
.layout-3col:after {
  content: "";
  display: table;
  clear: both;
}

/* line 57, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/layouts/layout-3col/_layout-3col.scss */
.layout-3col__full, .layout-3col__left-content, .layout-3col__right-content, .layout-3col__left-sidebar, .layout-3col__right-sidebar, .layout-3col__first-left-sidebar, .layout-3col__second-left-sidebar, .layout-3col__col-1, .layout-3col__col-2, .layout-3col__col-3, .layout-3col__col-4, .layout-3col__col-x {
  clear: both;
  padding-left: 20px;
  padding-right: 20px;
  float: left;
  width: 100%;
  margin-left: 0%;
  margin-right: -100%;
}

/* line 29, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/node_modules/zen-grids/sass/zen-grids/_grids.scss */
[dir="rtl"] .layout-3col__full, [dir="rtl"] .layout-3col__left-content, [dir="rtl"] .layout-3col__right-content, [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__right-sidebar, [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__second-left-sidebar, [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-4, [dir="rtl"] .layout-3col__col-x {
  float: right;
  margin-right: 0%;
  margin-left: -100%;
}

@media (min-width: 777px) {
  /* line 50, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/layouts/layout-3col/_layout-3col.scss */
  .layout-3col {
    margin-left: -12px;
    margin-right: -13px;
    padding-left: 0;
    padding-right: 0;
  }
  /* line 97, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/node_modules/zen-grids/sass/zen-grids/_grids.scss */
  .layout-3col:before {
    content: "";
    display: table;
  }
  /* line 101, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/node_modules/zen-grids/sass/zen-grids/_grids.scss */
  .layout-3col:after {
    content: "";
    display: table;
    clear: both;
  }
  /* line 29, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/node_modules/zen-grids/sass/zen-grids/_grids.scss */
  [dir="rtl"] .layout-3col {
    margin-left: -13px;
    margin-right: -12px;
  }
  /* line 79, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/layouts/layout-3col/_layout-3col.scss */
  .layout-3col__full, .layout-3col__left-content, .layout-3col__right-content, .layout-3col__left-sidebar, .layout-3col__right-sidebar {
    float: left;
    width: 100%;
    margin-left: 0%;
    margin-right: -100%;
    padding-left: 12px;
    padding-right: 13px;
  }
  /* line 29, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/node_modules/zen-grids/sass/zen-grids/_grids.scss */
  [dir="rtl"] .layout-3col__full, [dir="rtl"] .layout-3col__left-content, [dir="rtl"] .layout-3col__right-content, [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__right-sidebar {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  /* line 29, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/node_modules/zen-grids/sass/zen-grids/_grids.scss */
  [dir="rtl"] .layout-3col__full, [dir="rtl"] .layout-3col__left-content, [dir="rtl"] .layout-3col__right-content, [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__right-sidebar {
    padding-left: 13px;
    padding-right: 12px;
  }
  /* line 90, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/layouts/layout-3col/_layout-3col.scss */
  .layout-3col__first-left-sidebar, .layout-3col__col-1, .layout-3col__col-3, .layout-3col__col-x:nth-child(2n + 1) {
    float: left;
    width: 50%;
    margin-left: 0%;
    margin-right: -100%;
    padding-left: 12px;
    padding-right: 13px;
  }
  /* line 29, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/node_modules/zen-grids/sass/zen-grids/_grids.scss */
  [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-x:nth-child(2n + 1) {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  /* line 29, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/node_modules/zen-grids/sass/zen-grids/_grids.scss */
  [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-x:nth-child(2n + 1) {
    padding-left: 13px;
    padding-right: 12px;
  }
  /* line 98, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/layouts/layout-3col/_layout-3col.scss */
  .layout-3col__second-left-sidebar, .layout-3col__col-2, .layout-3col__col-4, .layout-3col__col-x:nth-child(2n) {
    clear: none;
    float: left;
    width: 50%;
    margin-left: 50%;
    margin-right: -100%;
    padding-left: 12px;
    padding-right: 13px;
  }
  /* line 29, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/node_modules/zen-grids/sass/zen-grids/_grids.scss */
  [dir="rtl"] .layout-3col__second-left-sidebar, [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-4, [dir="rtl"] .layout-3col__col-x:nth-child(2n) {
    float: right;
    margin-right: 50%;
    margin-left: -100%;
  }
  /* line 29, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/node_modules/zen-grids/sass/zen-grids/_grids.scss */
  [dir="rtl"] .layout-3col__second-left-sidebar, [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-4, [dir="rtl"] .layout-3col__col-x:nth-child(2n) {
    padding-left: 13px;
    padding-right: 12px;
  }
}

@media (min-width: 999px) {
  /* line 111, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/layouts/layout-3col/_layout-3col.scss */
  .layout-3col__full {
    float: left;
    width: 100%;
    margin-left: 0%;
    margin-right: -100%;
  }
  /* line 29, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/node_modules/zen-grids/sass/zen-grids/_grids.scss */
  [dir="rtl"] .layout-3col__full {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  /* line 115, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/layouts/layout-3col/_layout-3col.scss */
  .layout-3col__left-content {
    float: left;
    width: 66.66667%;
    margin-left: 0%;
    margin-right: -100%;
  }
  /* line 29, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/node_modules/zen-grids/sass/zen-grids/_grids.scss */
  [dir="rtl"] .layout-3col__left-content {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  /* line 119, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/layouts/layout-3col/_layout-3col.scss */
  .layout-3col__right-content {
    float: left;
    width: 66.66667%;
    margin-left: 33.33333%;
    margin-right: -100%;
  }
  /* line 29, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/node_modules/zen-grids/sass/zen-grids/_grids.scss */
  [dir="rtl"] .layout-3col__right-content {
    float: right;
    margin-right: 33.33333%;
    margin-left: -100%;
  }
  /* line 123, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/layouts/layout-3col/_layout-3col.scss */
  .layout-3col__left-sidebar, .layout-3col__first-left-sidebar, .layout-3col__second-left-sidebar {
    clear: right;
    float: right;
    width: 33.33333%;
    margin-right: 66.66667%;
    margin-left: -100%;
  }
  /* line 29, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/node_modules/zen-grids/sass/zen-grids/_grids.scss */
  [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__second-left-sidebar {
    clear: left;
  }
  /* line 29, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/node_modules/zen-grids/sass/zen-grids/_grids.scss */
  [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__second-left-sidebar {
    float: left;
    margin-left: 66.66667%;
    margin-right: -100%;
  }
  /* line 130, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/layouts/layout-3col/_layout-3col.scss */
  .layout-3col__right-sidebar {
    clear: right;
    float: right;
    width: 33.33333%;
    margin-right: 0%;
    margin-left: -100%;
  }
  /* line 29, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/node_modules/zen-grids/sass/zen-grids/_grids.scss */
  [dir="rtl"] .layout-3col__right-sidebar {
    clear: left;
  }
  /* line 29, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/node_modules/zen-grids/sass/zen-grids/_grids.scss */
  [dir="rtl"] .layout-3col__right-sidebar {
    float: left;
    margin-left: 0%;
    margin-right: -100%;
  }
  /* line 135, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/layouts/layout-3col/_layout-3col.scss */
  .layout-3col__col-1, .layout-3col__col-x:nth-child(3n+1) {
    clear: both;
    float: left;
    width: 33.33333%;
    margin-left: 0%;
    margin-right: -100%;
  }
  /* line 29, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/node_modules/zen-grids/sass/zen-grids/_grids.scss */
  [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-x:nth-child(3n+1) {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  /* line 141, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/layouts/layout-3col/_layout-3col.scss */
  .layout-3col__col-2, .layout-3col__col-x:nth-child(3n+2) {
    clear: none;
    float: left;
    width: 33.33333%;
    margin-left: 33.33333%;
    margin-right: -100%;
  }
  /* line 29, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/node_modules/zen-grids/sass/zen-grids/_grids.scss */
  [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-x:nth-child(3n+2) {
    float: right;
    margin-right: 33.33333%;
    margin-left: -100%;
  }
  /* line 147, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/layouts/layout-3col/_layout-3col.scss */
  .layout-3col__col-3, .layout-3col__col-x:nth-child(3n) {
    clear: none;
    float: left;
    width: 33.33333%;
    margin-left: 66.66667%;
    margin-right: -100%;
  }
  /* line 29, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/node_modules/zen-grids/sass/zen-grids/_grids.scss */
  [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-x:nth-child(3n) {
    float: right;
    margin-right: 66.66667%;
    margin-left: -100%;
  }
  /* line 153, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/layouts/layout-3col/_layout-3col.scss */
  .layout-3col__col-4 {
    display: none;
  }
}

/* line 159, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/layouts/layout-3col/_layout-3col.scss */
.layout-3col__grid-item-container {
  padding-left: 0;
  padding-right: 0;
}

/* line 97, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/node_modules/zen-grids/sass/zen-grids/_grids.scss */
.layout-3col__grid-item-container:before {
  content: "";
  display: table;
}

/* line 101, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/node_modules/zen-grids/sass/zen-grids/_grids.scss */
.layout-3col__grid-item-container:after {
  content: "";
  display: table;
  clear: both;
}

/* line 27, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/layouts/layout-center/_layout-center.scss */
.layout-center {
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;
  max-width: 1305px;
}

@media (min-width: 777px) {
  /* line 27, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/layouts/layout-center/_layout-center.scss */
  .layout-center {
    padding-left: 12px;
    padding-right: 13px;
  }
  /* line 29, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/node_modules/zen-grids/sass/zen-grids/_grids.scss */
  [dir="rtl"] .layout-center {
    padding-left: 13px;
    padding-right: 12px;
  }
}

/* line 48, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/layouts/layout-center/_layout-center.scss */
.layout-center--shared-grid,
.layout-center.layout-3col {
  padding-left: 0;
  padding-right: 0;
}

/* line 15, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/layouts/layout-swap/_layout-swap.scss */
.layout-swap {
  position: relative;
}

@media (min-width: 555px) {
  /* line 15, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/layouts/layout-swap/_layout-swap.scss */
  .layout-swap {
    padding-top: 0;
  }
}

/* line 24, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/box/_box.scss */
.box {
  margin-bottom: 1.42857rem;
  border: 5px solid #cccccc;
  padding: 1em;
}

/* line 33, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/box/_box.scss */
.box__title {
  margin: 0;
}

/* line 47, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/box/_box.scss */
.box:focus, .box:hover, .box.is-focus, .box--is-focus {
  border-color: #000;
}

/* line 58, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/box/_box.scss */
.box--highlight {
  border-color: #0072b9;
}

/* line 11, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/init/clearfix/_clearfix.scss */
.clearfix::before,
.header::before,
.tabs::before {
  content: '';
  display: table;
}

/* line 16, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/init/clearfix/_clearfix.scss */
.clearfix::after,
.header::after,
.tabs::after {
  content: '';
  display: table;
  clear: both;
}

/* line 17, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/comment/_comment.scss */
.comment__section,
.comments {
  margin: 1.42857rem 0;
}

/* line 21, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/comment/_comment.scss */
.comment__title {
  margin: 0;
}

/* line 26, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/comment/_comment.scss */
.comment__permalink {
  text-transform: uppercase;
  font-size: 75%;
}

/* line 32, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/comment/_comment.scss */
.comment--preview,
.comment-preview {
  background-color: #fffadb;
}

/* line 37, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/comment/_comment.scss */
.comment--nested,
.indented {
  margin-left: 28px;
}

/* line 18, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/init/rtl/_rtl.scss */
[dir="rtl"] .comment--nested,
[dir="rtl"] .indented {
  margin-left: 0;
  margin-right: 28px;
}

/* line 15, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/header/_header.scss */
.header .logo-name-and-slogan {
  margin: 2em auto auto 173px;
  display: inline-block;
}

/* line 20, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/header/_header.scss */
.header-right {
  float: right;
  margin-right: 173px;
  display: inline-block;
}

/* line 25, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/header/_header.scss */
.header-right #primary-menu {
  margin-top: 2em;
}

/* line 32, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/header/_header.scss */
.header__logo {
  margin: 0 10px 0 0;
  padding: 0;
}

/* line 37, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/header/_header.scss */
.header__logo img {
  width: 187px;
}

/* line 18, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/init/rtl/_rtl.scss */
[dir="rtl"] .header__logo {
  float: right;
  margin: 0 0 0 10px;
}

/* line 48, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/header/_header.scss */
.header__logo-image {
  vertical-align: bottom;
}

/* line 53, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/header/_header.scss */
.header__name-and-slogan {
  float: left;
}

/* line 58, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/header/_header.scss */
.header__site-name {
  margin: 0;
}

/* line 65, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/header/_header.scss */
.header__site-link:link, .header__site-link:visited {
  color: #000;
  text-decoration: none;
}

/* line 71, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/header/_header.scss */
.header__site-link:hover, .header__site-link:focus {
  text-decoration: underline;
}

/* line 78, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/header/_header.scss */
.header__site-slogan {
  margin: 0;
}

/* line 83, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/header/_header.scss */
.header__secondary-menu {
  text-align: right;
  font-size: 12px;
  text-transform: uppercase;
  margin-left: 10px;
  font-family: 'Open Sans', sans-serif;
}

/* line 18, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/init/rtl/_rtl.scss */
[dir="rtl"] .header__secondary-menu {
  float: left;
}

/* line 94, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/header/_header.scss */
.header__secondary-menu ul.menu {
  display: inline-block;
}

/* line 98, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/header/_header.scss */
.header__secondary-menu ul.menu li.last {
  padding-right: 0;
}

/* line 102, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/header/_header.scss */
.header__secondary-menu ul.menu li.first {
  padding: 0;
}

/* line 106, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/header/_header.scss */
.header__secondary-menu ul.menu li:nth-child(2) {
  padding: 0;
}

/* line 111, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/header/_header.scss */
.header__secondary-menu ul.menu li:nth-child(3) a {
  margin-right: 2em;
}

/* line 120, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/header/_header.scss */
.header__region {
  clear: both;
}

/* line 2, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/header_panels/_header_panels.scss */
.region-header-panels,
.layout-3col__right-sidebar {
  margin-top: -52px;
  position: relative;
  z-index: 2;
  text-align: center;
}

/* line 11, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/header_panels/_header_panels.scss */
.region-header-panels .block,
.layout-3col__right-sidebar .block {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  width: 270px;
  display: inline-block;
  margin-right: 26px;
  vertical-align: top;
  height: 140px;
  text-align: initial;
  background: #f9f9f9;
  padding: 11px 15px 15px 15px;
  -webkit-box-shadow: 0px 3px 3px 0px rgba(50, 50, 50, 0.55);
  -moz-box-shadow: 0px 3px 3px 0px rgba(50, 50, 50, 0.55);
  box-shadow: 0px 3px 3px 0px rgba(50, 50, 50, 0.55);
  -ms-box-shadow: 0px 3px 3px 0px rgba(50, 50, 50, 0.55);
  color: #666666;
  position: relative;
  margin-bottom: 30px;
}

/* line 13, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/header_panels/_header_panels.scss */
.region-header-panels .block.last,
.layout-3col__right-sidebar .block.last {
  margin-right: 0;
}

/* line 37, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/header_panels/_header_panels.scss */
.region-header-panels .block#block-menu-menu-liens-vedettes ul.menu,
.layout-3col__right-sidebar .block#block-menu-menu-liens-vedettes ul.menu {
  margin: 0;
  padding: 0;
}

/* line 42, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/header_panels/_header_panels.scss */
.region-header-panels .block#block-menu-menu-liens-vedettes ul.menu li.menu__item,
.layout-3col__right-sidebar .block#block-menu-menu-liens-vedettes ul.menu li.menu__item {
  line-height: 2.25rem;
  margin-bottom: 1.5em !important;
}

/* line 49, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/header_panels/_header_panels.scss */
.region-header-panels .block .block__title,
.region-header-panels .block#block-menu-menu-liens-vedettes ul.menu li.menu__item,
.layout-3col__right-sidebar .block .block__title,
.layout-3col__right-sidebar .block#block-menu-menu-liens-vedettes ul.menu li.menu__item {
  margin: 0 0 10px;
  padding: 0 0 10px;
  text-transform: uppercase;
  position: relative;
  display: block;
  font-size: 16px;
  font-weight: normal;
  color: #1c5b8c;
  font-family: 'Open Sans', sans-serif;
}

/* line 62, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/header_panels/_header_panels.scss */
.region-header-panels .block .block__title a,
.region-header-panels .block#block-menu-menu-liens-vedettes ul.menu li.menu__item a,
.layout-3col__right-sidebar .block .block__title a,
.layout-3col__right-sidebar .block#block-menu-menu-liens-vedettes ul.menu li.menu__item a {
  text-transform: uppercase;
  text-decoration: none;
  color: #1c5b8c;
}

/* line 68, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/header_panels/_header_panels.scss */
.region-header-panels .block .block__title::after,
.region-header-panels .block#block-menu-menu-liens-vedettes ul.menu li.menu__item::after,
.layout-3col__right-sidebar .block .block__title::after,
.layout-3col__right-sidebar .block#block-menu-menu-liens-vedettes ul.menu li.menu__item::after {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 47px;
  height: 2px;
  background-color: #1c5b8c;
}

/* line 2, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/footer/_footer.scss */
.region-footer {
  background: #F9F9F9 url("../images/footer-shadow.png") no-repeat 0 0px;
  text-align: center;
  padding: 22px 0 54px 0;
}

/* line 8, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/footer/_footer.scss */
.region-footer .block {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  width: 270px;
  display: inline-block;
  margin-right: 26px;
  vertical-align: top;
  text-align: initial;
  padding: 11px 15px 15px 15px;
  color: #666666;
  margin-bottom: 30px;
}

/* line 10, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/footer/_footer.scss */
.region-footer .block.last {
  margin-right: 0;
}

/* line 27, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/footer/_footer.scss */
.region-footer .block#block-formblock-contact-site input[type=text] {
  width: 100%;
}

/* line 32, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/footer/_footer.scss */
.region-footer .block .form-actions {
  margin-top: 10px;
}

/* line 38, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/footer/_footer.scss */
.region-bottom {
  background: #5b5b5b;
  opacity: 0.8;
  color: #fff;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: right;
}

/* line 50, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/footer/_footer.scss */
.region-bottom a {
  color: #fff;
}

/* line 54, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/footer/_footer.scss */
.region-bottom p {
  margin: 0;
}

/* line 58, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/footer/_footer.scss */
.region-bottom #block-block-3 {
  margin: auto 160px;
}

/* line 61, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/footer/_footer.scss */
.region-bottom #block-block-3 ul {
  margin: 0;
  padding: 0;
}

/* line 66, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/footer/_footer.scss */
.region-bottom #block-block-3 ul li {
  margin: 0 0 0 10px;
  display: inline-block;
}

/* line 70, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/footer/_footer.scss */
.region-bottom #block-block-3 ul li:first-child {
  float: left;
  margin: 0;
}

/* line 16, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/hidden/_hidden.scss */
.hidden,
html.js .js-hidden,
html.js .element-hidden,
html.js .js-hide {
  display: none;
}

/* line 11, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/highlight-mark/_highlight-mark.scss */
.highlight-mark,
.new,
.update {
  color: #c00;
  background-color: transparent;
}

/* line 9, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/inline-links/_inline-links.scss */
.inline-links,
.inline.links {
  padding: 0;
}

/* line 13, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/inline-links/_inline-links.scss */
.inline-links__item,
.inline.links li {
  display: inline;
  list-style-type: none;
  padding: 0 1em 0 0;
}

/* line 18, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/init/rtl/_rtl.scss */
[dir="rtl"] .inline-links__item,
[dir="rtl"] .inline.links li,
.inline.links [dir="rtl"] li {
  display: inline-block;
  padding: 0 0 0 1em;
}

/* line 9, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/inline-sibling/_inline-sibling.scss */
.inline-sibling,
.field-label-inline .field-label,
span.field-label {
  display: inline;
  margin-right: 10px;
}

/* line 18, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/init/rtl/_rtl.scss */
[dir="rtl"] .inline-sibling,
[dir="rtl"] .field-label-inline .field-label,
.field-label-inline [dir="rtl"] .field-label,
[dir="rtl"] span.field-label {
  margin-right: 0;
  margin-left: 10px;
}

/* line 20, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/inline-sibling/_inline-sibling.scss */
.inline-sibling__child,
.inline-sibling *, .field-label-inline .field-label *, span.field-label * {
  display: inline;
}

/* line 27, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/inline-sibling/_inline-sibling.scss */
.inline-sibling__adjacent,
.inline-sibling + *,
.inline-sibling + * > :first-child,
.inline-sibling + * > :first-child > :first-child, .field-label-inline .field-label + *, span.field-label + *, .field-label-inline .field-label + * > :first-child, span.field-label + * > :first-child, .field-label-inline .field-label + * > :first-child > :first-child, span.field-label + * > :first-child > :first-child {
  display: inline;
}

/* line 46, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/inline-sibling/_inline-sibling.scss */
.field-label-inline .field-label,
.field-label-inline .field-items {
  float: none;
}

/* line 27, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/messages/_messages.scss */
.messages,
.messages--status {
  margin: 1.42857rem 0;
  position: relative;
  padding: 10px 10px 10px 44px;
  border: 1px solid #0072b9;
}

/* line 18, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/init/rtl/_rtl.scss */
[dir="rtl"] .messages,
[dir="rtl"] .messages--status {
  padding: 10px 44px 10px 10px;
  background-position: 99% 8px;
}

/* line 40, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/messages/_messages.scss */
.messages__icon {
  position: absolute;
  top: 50%;
  left: 10px;
  height: 24px;
  width: 24px;
  margin-top: -12px;
  line-height: 1;
}

/* line 18, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/init/rtl/_rtl.scss */
[dir="rtl"] .messages__icon {
  left: auto;
  right: 0;
}

/* line 55, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/messages/_messages.scss */
.messages__icon path {
  fill: #0072b9;
}

/* line 60, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/messages/_messages.scss */
.messages__highlight,
.messages--error .error,
.messages.error .error {
  color: #000;
}

/* line 64, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/messages/_messages.scss */
.messages__list {
  margin: 0;
}

/* line 68, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/messages/_messages.scss */
.messages__item {
  list-style-image: none;
}

/* line 72, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/messages/_messages.scss */
.messages--ok-color,
.messages,
.messages--status,
.ok,
.revision-current {
  background-color: #f6fcff;
  color: #0072b9;
}

/* line 77, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/messages/_messages.scss */
.messages--warning-color,
.messages--warning,
.messages.warning,
.warning {
  background-color: #fffce6;
  color: #000;
}

/* line 82, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/messages/_messages.scss */
.messages--error-color,
.messages--error,
.messages.error,
.error {
  background-color: #fff0f0;
  color: #c00;
}

/* line 91, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/messages/_messages.scss */
.messages--warning,
.messages.warning {
  border-color: #fd0;
}

/* line 95, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/messages/_messages.scss */
.messages--warning path, .messages.warning path {
  fill: #fd0;
}

/* line 100, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/messages/_messages.scss */
.messages--error,
.messages.error {
  border-color: #c00;
}

/* line 104, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/messages/_messages.scss */
.messages--error path, .messages.error path {
  fill: #c00;
}

@media print {
  /* line 13, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/print-none/_print-none.scss */
  .print-none,
  .toolbar,
  .action-links,
  .links,
  .book-navigation,
  .forum-topic-navigation,
  .feed-icons {
    display: none;
  }
}

/* line 14, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/responsive-video/_responsive-video.scss */
.responsive-video,
.media-youtube-video,
.media-vimeo-preview-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
}

/* line 25, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/responsive-video/_responsive-video.scss */
.responsive-video__embed,
.responsive-video iframe,
.media-youtube-video iframe,
.media-vimeo-preview-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* line 33, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/responsive-video/_responsive-video.scss */
.responsive-video--4-3 {
  padding-bottom: 75%;
}

/* line 14, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/visually-hidden/_visually-hidden.scss */
.visually-hidden,
.element-invisible,
.element-focusable,
.breadcrumb__title,
.main-navigation .block-menu .block__title,
.main-navigation .block-menu-block .block__title {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  word-wrap: normal;
}

/* line 18, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/visually-hidden/_visually-hidden.scss */
.visually-hidden--off,
.visually-hidden--focusable:active,
.visually-hidden--focusable:focus,
.element-focusable:active,
.element-focusable:focus {
  position: static !important;
  clip: auto;
  height: auto;
  width: auto;
  overflow: visible;
}

/* line 9, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/watermark/_watermark.scss */
.watermark {
  font-family: Impact, "Arial Narrow", Helvetica, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  display: block;
  height: 0;
  overflow: visible;
  background-color: transparent;
  color: #eeeeee;
  font-size: 75px;
  line-height: 1;
  text-align: center;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
  word-wrap: break-word;
}

/**
 * Homepage main slider
 */
/* line 8, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/flexslider/_flexslider.scss */
.flexslider-diaporama .flexslider {
  margin: 0;
  border: none;
}

/* line 13, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/flexslider/_flexslider.scss */
.flexslider-diaporama .flexslider .flex-caption {
  position: absolute;
  top: 32%;
  left: 10%;
  width: 338px;
  background: rgba(35, 96, 143, 0.9);
  padding: 13px 19px 16px;
  color: #fff;
}

/* line 22, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/flexslider/_flexslider.scss */
.flexslider-diaporama .flexslider .flex-caption p:last-child {
  margin-bottom: 0;
}

/* line 26, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/flexslider/_flexslider.scss */
.flexslider-diaporama .flexslider .flex-caption p:first-child {
  margin-top: 0;
}

/* line 32, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/flexslider/_flexslider.scss */
.flexslider-diaporama .flexslider .flex-direction-nav a::before {
  line-height: 40px;
}

/* line 37, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/flexslider/_flexslider.scss */
.flexslider-diaporama .flexslider .flex-control-nav {
  bottom: 73px;
  width: auto;
  right: 208px;
  z-index: 2;
}

/* line 44, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/flexslider/_flexslider.scss */
.flexslider-diaporama .flexslider .flex-pauseplay {
  position: absolute;
  right: 160px;
  bottom: 70px;
}

/* line 49, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/flexslider/_flexslider.scss */
.flexslider-diaporama .flexslider .flex-pauseplay a::before {
  color: #fff;
}

/* line 54, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/flexslider/_flexslider.scss */
.flexslider-diaporama .flexslider .flex-control-paging li a {
  background: #1c5b8c;
}

/* line 58, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/flexslider/_flexslider.scss */
.flexslider-diaporama .flexslider .flex-control-paging li a:not(.flex-active) {
  background: #fff;
}

/**
 * Homepage news slider
 */
/* line 78, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/flexslider/_flexslider.scss */
.region-header-panels .block .flexslider-news .flexslider,
.region-header-panels .block .flexslider-publications .flexslider {
  margin: 0;
  padding: 0;
  border: 0;
  border-radius: 0;
  background: none;
}

/* line 86, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/flexslider/_flexslider.scss */
.region-header-panels .block .flexslider-news .flexslider .field-content p:first-child,
.region-header-panels .block .flexslider-publications .flexslider .field-content p:first-child {
  margin-top: 0;
}

/* line 89, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/flexslider/_flexslider.scss */
.region-header-panels .block .flexslider-news .flexslider .field-content p:last-child,
.region-header-panels .block .flexslider-publications .flexslider .field-content p:last-child {
  margin-bottom: 0;
}

/* line 96, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/flexslider/_flexslider.scss */
.region-header-panels .block .flexslider-news .flexslider .flex-direction-nav a,
.region-header-panels .block .flexslider-publications .flexslider .flex-direction-nav a {
  top: -30px;
  display: block;
  opacity: 0.5;
  width: 15px;
}

/* line 102, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/flexslider/_flexslider.scss */
.region-header-panels .block .flexslider-news .flexslider .flex-direction-nav a:hover,
.region-header-panels .block .flexslider-publications .flexslider .flex-direction-nav a:hover {
  opacity: 1;
}

/* line 106, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/flexslider/_flexslider.scss */
.region-header-panels .block .flexslider-news .flexslider .flex-direction-nav a::before,
.region-header-panels .block .flexslider-publications .flexslider .flex-direction-nav a::before {
  line-height: 40px;
  font-size: 20px;
  font-family: "flexslider-icon";
  display: inline-block;
  content: '\f001';
  color: rgba(0, 0, 0, 0.8);
  text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.3);
}

/* line 118, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/flexslider/_flexslider.scss */
.region-header-panels .block .flexslider-news .flexslider .flex-direction-nav .flex-prev,
.region-header-panels .block .flexslider-publications .flexslider .flex-direction-nav .flex-prev {
  left: initial;
  right: 15px;
}

/* line 123, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/flexslider/_flexslider.scss */
.region-header-panels .block .flexslider-news .flexslider .flex-direction-nav .flex-next,
.region-header-panels .block .flexslider-publications .flexslider .flex-direction-nav .flex-next {
  right: 0;
}

/* line 126, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/flexslider/_flexslider.scss */
.region-header-panels .block .flexslider-news .flexslider .flex-direction-nav .flex-next::before,
.region-header-panels .block .flexslider-publications .flexslider .flex-direction-nav .flex-next::before {
  content: '\f002';
}

@media all and (max-width: 1230px) {
  /* line 5, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/responsive/_responsive.scss */
  .header-right {
    margin-right: 0;
  }
}

@media all and (max-width: 1060px) {
  /* line 14, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/responsive/_responsive.scss */
  .header .logo-name-and-slogan {
    margin-left: 0;
  }
  /* line 19, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/responsive/_responsive.scss */
  .layout-3col {
    padding-bottom: 30px;
    margin: 0 !important;
  }
}

@media all and (max-width: 999px) {
  /**
   * Homepage main slider
   */
  /* line 46, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/responsive/_responsive.scss */
  .flexslider-diaporama .flexslider .flex-control-nav {
    bottom: 0;
  }
  /* line 50, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/responsive/_responsive.scss */
  .flexslider-diaporama .flexslider .flex-pauseplay {
    bottom: -3px;
  }
  /**
   * Main menu
   */
  /**
   * Header panels
   */
  /* line 70, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/responsive/_responsive.scss */
  .region-header-panels {
    margin-top: 10px;
    z-index: initial;
  }
  /* line 75, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/responsive/_responsive.scss */
  .region-header-panels .block {
    display: block;
    width: initial;
    margin-right: 0;
  }
  /* line 86, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/responsive/_responsive.scss */
  .region-footer .block {
    width: 25%;
  }
  /* line 91, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/responsive/_responsive.scss */
  .region-bottom {
    min-height: 40px;
    height: initial;
    text-align: center;
  }
  /* line 96, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/responsive/_responsive.scss */
  .region-bottom #block-block-3 {
    margin: initial;
  }
  /* line 101, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/responsive/_responsive.scss */
  .region-bottom #block-block-3 ul li {
    margin: 0 5px;
    display: inline-block;
  }
  /* line 105, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/responsive/_responsive.scss */
  .region-bottom #block-block-3 ul li:first-child {
    float: initial;
  }
}

@media all and (max-width: 979px) {
  /* line 116, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/responsive/_responsive.scss */
  .tb-megamenu .nav-collapse {
    background: #fff;
    width: auto;
    right: 0;
  }
  /* line 121, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/responsive/_responsive.scss */
  .tb-megamenu .nav-collapse a,
  .tb-megamenu .nav-collapse a:hover {
    border-bottom: 1px solid #222 !important;
    border-right: none !important;
    font-weight: inherit !important;
    color: black;
  }
  /* line 129, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/responsive/_responsive.scss */
  .tb-megamenu .nav-collapse .dropdown-menu {
    background: white;
  }
  /* line 135, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/responsive/_responsive.scss */
  .secondary-menu-wrapper {
    display: none;
  }
}

@media all and (max-width: 767px) {
  /* line 145, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/responsive/_responsive.scss */
  .logo-name-and-slogan {
    text-align: center;
  }
  /* line 149, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/responsive/_responsive.scss */
  .logo-name-and-slogan .header__logo {
    float: none;
  }
  /**
   * Homepage main slider
   */
  /* line 162, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/responsive/_responsive.scss */
  .flexslider-diaporama .flexslider .flex-control-nav,
  .flexslider-diaporama .flexslider .flex-pauseplay {
    display: none;
  }
  /* line 167, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/responsive/_responsive.scss */
  .flexslider-diaporama .flexslider .flex-caption {
    top: initial;
    bottom: 0;
    left: 0;
    width: auto;
  }
  /* line 179, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/responsive/_responsive.scss */
  .region-footer .block {
    width: initial;
    display: block;
    margin: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  }
  /* line 187, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/responsive/_responsive.scss */
  .region-footer .block#block-block-2 img {
    width: 100%;
  }
}

@media all and (max-width: 600px) {
  /**
   * Homepage main slider
   */
  /* line 208, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/responsive/_responsive.scss */
  .flexslider-diaporama .flexslider .flex-caption {
    display: none;
  }
}

/* line 3, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/general/_general.scss */
.logged-in .tabs {
  margin-bottom: 1em;
}

/* line 8, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/general/_general.scss */
.page-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  background: #1c5b8c;
  color: #ffffff;
  padding: 9px 13px;
  text-transform: uppercase;
  margin-bottom: 25px;
  line-height: 1.607em;
}

/* line 19, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/general/_general.scss */
.front .page-title {
  text-align: center;
  font-weight: normal;
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  background: #1c5b8c;
  color: #ffffff;
  padding: 9px 13px;
  text-transform: uppercase;
  margin-bottom: 25px;
}

/* line 33, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/general/_general.scss */
.layout-3col {
  padding-bottom: 30px;
  margin: 0 173px;
}

/* line 40, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/general/_general.scss */
.view-id-news.news .view-content {
  background: #f9f9f9;
  padding: 15px;
  border: 1px #cccccc solid;
  margin-bottom: 30px;
  color: #666666;
}

/* line 48, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/general/_general.scss */
.view-id-news.news .pager {
  text-align: initial;
}

/* line 53, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/general/_general.scss */
.layout-3col__right-sidebar {
  margin-top: 3.4em;
  position: initial;
  text-align: initial;
}

/* line 59, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/general/_general.scss */
.layout-3col__right-sidebar .block {
  margin-right: 0;
}

/* line 68, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/general/_general.scss */
.listing-publications .view-content .views-row {
  display: table;
  width: 100%;
  background: #f9f9f9;
  padding: 15px;
  border: 1px #cccccc solid;
  margin-bottom: 30px;
  color: #666666;
}

/* line 77, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/general/_general.scss */
.listing-publications .view-content .views-row .views-fieldset {
  display: table-cell;
  vertical-align: top;
}

/* line 81, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/general/_general.scss */
.listing-publications .view-content .views-row .views-fieldset.left {
  width: 100px;
  overflow-x: hidden;
}

/* line 85, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/general/_general.scss */
.listing-publications .view-content .views-row .views-fieldset.left img {
  display: block;
}

/* line 90, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/general/_general.scss */
.listing-publications .view-content .views-row .views-fieldset.right {
  border-left: 15px solid transparent;
}

/* line 93, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/general/_general.scss */
.listing-publications .view-content .views-row .views-fieldset.right .views-field-title-field a {
  font-size: 18px;
  font-family: "Open Sans", sans-serif;
  color: #1c5b8c;
  text-decoration: none;
}

/* line 100, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/general/_general.scss */
.listing-publications .view-content .views-row .views-fieldset.right .views-field-body {
  margin-top: 0.5em;
}

/* line 104, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/general/_general.scss */
.listing-publications .view-content .views-row .views-fieldset.right .views-field-view-node {
  text-align: right;
}

/* line 107, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/general/_general.scss */
.listing-publications .view-content .views-row .views-fieldset.right .views-field-view-node a {
  position: relative;
  font-size: 12px;
  background: #1C5B8C;
  color: #FFF;
  padding: 0 10px;
  line-height: 1.7;
  display: block;
  margin-right: 21px;
  float: right;
  margin-top: 15px;
  text-decoration: none;
}

/* line 136, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/general/_general.scss */
.node-type-publication .view-publication-files .view-content .item-list ul {
  margin: 0;
  padding: 0;
}

/* line 141, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/general/_general.scss */
.node-type-publication .view-publication-files .view-content .item-list li {
  list-style: none;
}

/* line 145, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/general/_general.scss */
.node-type-publication .view-publication-files .view-content .item-list .views-row {
  background: #f9f9f9;
  padding: 15px;
  border: 1px #cccccc solid;
  margin-bottom: 30px;
  color: #666666;
}

/* line 153, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/general/_general.scss */
.node-type-publication .view-publication-files .view-content .item-list .views-row .views-field-filename-field-et a {
  text-decoration: none;
}

/* line 158, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/general/_general.scss */
.node-type-publication .view-publication-files .view-content .item-list .views-row .views-field-download {
  text-align: right;
}

/* line 162, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/general/_general.scss */
.node-type-publication .view-publication-files .view-content .item-list .views-row .views-field-download a {
  position: relative;
  text-decoration: none;
  font-size: 12px;
  background: #1C5B8C;
  color: #FFF;
  padding: 0 10px;
  line-height: 1.7;
  display: inline-block;
  margin: 15px 21px 0 0;
}

/* line 173, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/general/_general.scss */
.node-type-publication .view-publication-files .view-content .item-list .views-row .views-field-download a:after {
  content: '';
  position: absolute;
  right: -21px;
  background: url("../images/icon-download.png") no-repeat right;
  top: 0;
  bottom: 0;
  display: inline-block;
  height: 20px;
  width: 20px;
}

/* line 194, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/general/_general.scss */
.page-contact form.contact-form {
  background: #f9f9f9;
  padding: 15px 30px;
  margin-bottom: 30px;
  position: relative;
  border: 1px #ccc solid;
}

/* line 204, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/general/_general.scss */
#block-block-4 .content {
  font-size: 0;
  height: 200px;
  background: url("../images/banners/istock-451567141.jpg") no-repeat center center;
}

/* line 211, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/general/_general.scss */
body.prestataires #block-block-4 .content {
  background-image: url("../images/banners/istock-125183558.jpg");
}

/* line 216, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/general/_general.scss */
body.publications #block-block-4 .content {
  background-image: url("../images/banners/istock-503489106.jpg");
  background-position-y: top;
}

/* line 222, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/general/_general.scss */
body.contact #block-block-4 .content {
  background-image: url("../images/banners/istock-505620793.jpg");
}

/* line 227, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/general/_general.scss */
body.philosophie #block-block-4 .content {
  background-image: url("../images/banners/istock-505620793.jpg");
}

/* line 232, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/general/_general.scss */
body.offre #block-block-4 .content {
  background-image: url("../images/banners/istock-125183558.jpg");
}

/* line 237, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/general/_general.scss */
body.fonds #block-block-4 .content {
  background-image: url("../images/banners/istock-451567141.jpg");
  background-position: center top;
}

/* line 244, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/general/_general.scss */
#block-block-6 {
  margin-top: 2em;
}

/* line 248, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/general/_general.scss */
.tb-megamenu {
  float: right;
  background-color: transparent !important;
}

/* line 255, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/general/_general.scss */
.tb-megamenu .nav > li.dropdown.active,
.tb-megamenu .nav li.dropdown.open.active,
.tb-megamenu .nav > .active,
.tb-megamenu .nav > li {
  border: none;
  border-right: 1px solid #ddd;
}

/* line 263, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/general/_general.scss */
.tb-megamenu .nav > li.dropdown.active.last,
.tb-megamenu .nav li.dropdown.open.active.last,
.tb-megamenu .nav > .active.last,
.tb-megamenu .nav > li.last {
  border-right: none;
}

/* line 267, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/general/_general.scss */
.tb-megamenu .nav > li.dropdown.active.last a,
.tb-megamenu .nav li.dropdown.open.active.last a,
.tb-megamenu .nav > .active.last a,
.tb-megamenu .nav > li.last a {
  padding-right: 0;
  border-right: none;
}

/* line 273, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/general/_general.scss */
.tb-megamenu .nav > li.dropdown.active > a,
.tb-megamenu .nav > li.dropdown.active > a:hover,
.tb-megamenu .nav > li.dropdown.active > a.active,
.tb-megamenu .nav > li.dropdown.active > .dropdown-toggle,
.tb-megamenu .nav li.dropdown.open.active > a,
.tb-megamenu .nav li.dropdown.open.active > a:hover,
.tb-megamenu .nav li.dropdown.open.active > a.active,
.tb-megamenu .nav li.dropdown.open.active > .dropdown-toggle,
.tb-megamenu .nav > .active > a,
.tb-megamenu .nav > .active > a:hover,
.tb-megamenu .nav > .active > a.active,
.tb-megamenu .nav > .active > .dropdown-toggle,
.tb-megamenu .nav > li > a,
.tb-megamenu .nav > li > a:hover,
.tb-megamenu .nav > li > a.active,
.tb-megamenu .nav > li > .dropdown-toggle {
  border: none;
  font-weight: normal;
  color: #000 !important;
  text-decoration: none;
  background-color: transparent;
}

/* line 284, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/general/_general.scss */
.tb-megamenu .nav > li.dropdown.active > a:hover,
.tb-megamenu .nav li.dropdown.open.active > a:hover,
.tb-megamenu .nav > .active > a:hover,
.tb-megamenu .nav > li > a:hover {
  color: #1c5b8c;
}

/* line 289, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/general/_general.scss */
.tb-megamenu .nav li.dropdown.active > .dropdown-toggle {
  background-color: #ffffff;
  border-color: transparent;
}

/* line 296, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/general/_general.scss */
.tb-megamenu .dropdown-menu {
  background: rgba(241, 243, 245, 0.8);
  border-bottom: 3px solid #1c5b8c;
}

/* line 300, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/general/_general.scss */
.tb-megamenu .dropdown-menu a {
  text-decoration: none;
  border: none;
  font-weight: normal;
  color: #000000 !important;
  text-decoration: none;
  background: none;
  background-color: transparent !important;
  background-image: none !important;
  color: #000;
}

/* line 311, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/general/_general.scss */
.tb-megamenu .dropdown-menu a:hover {
  color: #1c5b8c !important;
}

/* line 322, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/general/_general.scss */
main.layout-3col__full .card-layout .view-content .views-row {
  padding: 20px;
  clear: both;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  margin-bottom: 1em;
}

/* line 332, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/general/_general.scss */
main.layout-3col__full .card-layout .views-field-field-apercu {
  width: 100px;
  height: 100px;
  float: left;
  margin-right: 2em;
}

/* line 339, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/general/_general.scss */
main.layout-3col__full .card-layout .views-field-title {
  margin-bottom: 0.5em;
}

/* line 343, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/components/general/_general.scss */
main.layout-3col__full .card-layout .views-field-title a {
  font-size: 18px;
  text-decoration: none;
  text-transform: uppercase;
}

/* line 12, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/navigation/breadcrumb/_breadcrumb.scss */
.breadcrumb {
  margin: 11px 0 0;
  color: #1c5b8c;
  font-size: 12px;
}

@media print {
  /* line 12, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/navigation/breadcrumb/_breadcrumb.scss */
  .breadcrumb {
    display: none;
  }
}

/* line 27, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/navigation/breadcrumb/_breadcrumb.scss */
.breadcrumb__list {
  margin: 0;
  padding: 0;
}

/* line 32, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/navigation/breadcrumb/_breadcrumb.scss */
.breadcrumb__item {
  display: inline;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

/* line 9, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/navigation/more-link/_more-link.scss */
.more-link,
.more-help-link {
  text-align: right;
}

/* line 18, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/init/rtl/_rtl.scss */
[dir="rtl"] .more-link,
[dir="rtl"] .more-help-link {
  text-align: left;
}

/* line 17, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/navigation/more-link/_more-link.scss */
.more-link__help-icon,
.more-help-link a {
  padding: 1px 0 1px 20px;
  background-image: url(../sass/navigation/more-link/more-link__help-icon.svg);
  background-position: 0 50%;
  background-repeat: no-repeat;
}

/* line 18, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/init/rtl/_rtl.scss */
[dir="rtl"] .more-link__help-icon,
[dir="rtl"] .more-help-link a,
.more-help-link [dir="rtl"] a {
  padding: 1px 20px 1px 0;
  background-position: 100% 50%;
}

/* line 13, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/navigation/nav-menu/_nav-menu.scss */
.nav-menu__item,
.menu__item {
  list-style-image: url(../sass/navigation/nav-menu/leaf.svg);
  list-style-type: square;
}

/* line 17, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/navigation/nav-menu/_nav-menu.scss */
.nav-menu__item.is-expanded, .nav-menu__item--is-expanded,
.is-expanded.menu__item,
.menu__item.is-expanded {
  list-style-image: url(../sass/navigation/nav-menu/expanded.svg);
  list-style-type: circle;
}

/* line 23, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/navigation/nav-menu/_nav-menu.scss */
.nav-menu__item.is-collapsed, .nav-menu__item--is-collapsed,
.is-collapsed.menu__item,
.menu__item.is-collapsed {
  list-style-image: url(../sass/navigation/nav-menu/collapsed.svg);
  list-style-type: disc;
}

/* line 18, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/init/rtl/_rtl.scss */
[dir="rtl"] .nav-menu__item.is-collapsed, [dir="rtl"] .nav-menu__item--is-collapsed,
[dir="rtl"] .is-collapsed.menu__item,
[dir="rtl"] .menu__item.is-collapsed {
  list-style-image: url(../sass/navigation/nav-menu/collapsed-rtl.svg);
}

/* line 35, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/navigation/nav-menu/_nav-menu.scss */
.nav-menu__link.is-active, .nav-menu__link--is-active,
.menu a.active {
  color: #000;
}

/* line 12, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/navigation/navbar/_navbar.scss */
.navbar,
.main-navigation .links,
.main-navigation .menu {
  margin: 0;
  padding: 0;
  text-align: left;
  display: inline-block;
}

/* line 18, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/init/rtl/_rtl.scss */
[dir="rtl"] .navbar,
[dir="rtl"] .main-navigation .links,
.main-navigation [dir="rtl"] .links,
[dir="rtl"]
.main-navigation .menu,
.main-navigation [dir="rtl"] .menu {
  text-align: right;
}

/* line 23, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/navigation/navbar/_navbar.scss */
.navbar__item,
.navbar li, .main-navigation .links li,
.main-navigation .menu li {
  float: left;
  padding: 0 10px 0 0;
  list-style-type: none;
  list-style-image: none;
}

/* line 18, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/init/rtl/_rtl.scss */
[dir="rtl"] .navbar__item, [dir="rtl"]
.navbar li, [dir="rtl"] .main-navigation .links li, .main-navigation [dir="rtl"] .links li, [dir="rtl"]
.main-navigation .menu li,
.main-navigation [dir="rtl"] .menu li {
  float: right;
  padding: 0 0 0 10px;
}

/* line 38, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/navigation/navbar/_navbar.scss */
nav.main-menu {
  text-align: right;
}

/* line 42, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/navigation/navbar/_navbar.scss */
nav.main-menu ul.menu li.last {
  padding-right: 0;
}

/* line 10, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/navigation/pager/_pager.scss */
.pager {
  clear: both;
  padding: 0;
  text-align: center;
}

@media print {
  /* line 10, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/navigation/pager/_pager.scss */
  .pager {
    display: none;
  }
}

/* line 21, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/navigation/pager/_pager.scss */
.pager__item,
.pager__current-item,
.pager-current,
.pager-item,
.pager-first,
.pager-previous,
.pager-next,
.pager-last,
.pager-ellipsis {
  display: inline;
  padding: 0 .5em;
  list-style-type: none;
  background-image: none;
}

/* line 29, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/navigation/pager/_pager.scss */
.pager__current-item,
.pager-current {
  font-weight: bold;
}

/* line 12, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/navigation/skip-link/_skip-link.scss */
.skip-link {
  display: block;
  padding: 2px 0 3px;
  text-align: center;
}

/* line 18, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/navigation/skip-link/_skip-link.scss */
.skip-link:link, .skip-link:visited {
  background-color: #666666;
  color: #fff;
}

/* line 24, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/navigation/skip-link/_skip-link.scss */
.skip-link__wrapper {
  margin: 0;
}

@media print {
  /* line 24, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/navigation/skip-link/_skip-link.scss */
  .skip-link__wrapper {
    display: none;
  }
}

/* line 23, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/navigation/tabs/_tabs.scss */
.tabs {
  margin: 1.07143rem 0 0;
  line-height: 1.78571rem;
  border-bottom: 1px solid #cccccc \0/ie;
  background-image: linear-gradient(to top, #cccccc 1px, transparent 1px);
  padding: 0 2px;
  list-style: none;
  white-space: nowrap;
}

@media print {
  /* line 23, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/navigation/tabs/_tabs.scss */
  .tabs {
    display: none;
  }
}

/* line 45, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/navigation/tabs/_tabs.scss */
.tabs__tab {
  float: left;
  margin: 0 3px;
  border: 1px solid #cccccc;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-color: transparent;
  border-bottom: 0 \0/ie;
  overflow: hidden;
  background: #dddddd;
}

/* line 18, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/init/rtl/_rtl.scss */
[dir="rtl"] .tabs__tab {
  float: right;
}

/* line 65, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/navigation/tabs/_tabs.scss */
.tabs__tab.is-active {
  border-bottom-color: #fff;
}

/* line 71, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/navigation/tabs/_tabs.scss */
.tabs__tab-link {
  padding: 0 1.42857rem;
  display: block;
  text-decoration: none;
  transition: color .3s, background .3s, border .3s;
  text-shadow: #fff 0 1px 0;
  color: #000;
  background: #dddddd;
  letter-spacing: 1px;
}

/* line 82, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/navigation/tabs/_tabs.scss */
.tabs__tab-link:focus, .tabs__tab-link:hover {
  background: #eaeaea;
}

/* line 87, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/navigation/tabs/_tabs.scss */
.tabs__tab-link:active, .tabs__tab-link.is-active, .tabs__tab-link--is-active {
  background: #fff;
  text-shadow: none;
}

/* line 96, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/navigation/tabs/_tabs.scss */
.tabs--off,
.views-displays .secondary {
  margin: 0;
  border-bottom: 0;
  padding: 0;
  background-image: none;
}

/* line 105, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/navigation/tabs/_tabs.scss */
.tabs--secondary {
  margin-top: 1.42857rem;
  font-size: 1rem;
  background-image: none;
}

/* line 112, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/navigation/tabs/_tabs.scss */
.tabs + .tabs--secondary {
  margin-top: 0;
  background-image: linear-gradient(to top, #cccccc 1px, transparent 1px);
}

/* line 117, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/navigation/tabs/_tabs.scss */
.tabs--secondary .tabs__tab {
  margin: 0.53571rem 0.21429rem;
  border: 0;
  background: transparent;
}

/* line 18, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/init/rtl/_rtl.scss */
[dir="rtl"] .tabs--secondary .tabs__tab {
  float: right;
}

/* line 126, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/navigation/tabs/_tabs.scss */
.tabs--secondary .tabs__tab.is-active {
  border-bottom-color: transparent;
}

/* line 131, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/navigation/tabs/_tabs.scss */
.tabs--secondary .tabs__tab-link {
  border: 1px solid #cccccc;
  border-radius: 20px;
  color: #666666;
  background: #f1f1f1;
  letter-spacing: normal;
}

/* line 138, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/navigation/tabs/_tabs.scss */
.tabs--secondary .tabs__tab-link:focus, .tabs--secondary .tabs__tab-link:hover {
  color: #333333;
  background: #dddddd;
  border-color: #999999;
}

/* line 145, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/navigation/tabs/_tabs.scss */
.tabs--secondary .tabs__tab-link:active, .tabs--secondary .tabs__tab-link.is-active, .tabs--secondary .tabs__tab-link--is-active {
  color: white;
  text-shadow: #333333 0 1px 0;
  background: #666666;
  border-color: black;
}

/* line 14, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/forms/autocomplete/_autocomplete.scss */
.autocomplete,
.form-autocomplete {
  background-image: url(../sass/forms/autocomplete/throbber-inactive.png);
  background-position: 100% center;
  background-repeat: no-repeat;
}

/* line 18, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/init/rtl/_rtl.scss */
[dir="rtl"] .autocomplete,
[dir="rtl"] .form-autocomplete {
  background-position: 0% center;
}

/* line 25, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/forms/autocomplete/_autocomplete.scss */
.autocomplete__list-wrapper,
#autocomplete {
  border: 1px solid;
  overflow: hidden;
  position: absolute;
  z-index: 100;
}

/* line 32, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/forms/autocomplete/_autocomplete.scss */
.autocomplete__list,
#autocomplete ul {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
}

/* line 39, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/forms/autocomplete/_autocomplete.scss */
.autocomplete__list-item,
#autocomplete li {
  background: #fff;
  color: #000;
  cursor: default;
  white-space: pre;
}

/* line 45, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/forms/autocomplete/_autocomplete.scss */
.autocomplete__list-item.is-selected, .autocomplete__list-item--is-selected,
#autocomplete li.is-selected,
#autocomplete .selected {
  background: #0072b9;
  color: #fff;
}

/* line 52, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/forms/autocomplete/_autocomplete.scss */
.autocomplete.is-throbbing, .autocomplete--is-throbbing,
.is-throbbing.form-autocomplete,
.form-autocomplete.throbbing {
  background-image: url(../sass/forms/autocomplete/throbber-active.gif);
}

/* line 11, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/forms/collapsible-fieldset/_collapsible-fieldset.scss */
.collapsible-fieldset,
.collapsible {
  position: relative;
}

/* line 15, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/forms/collapsible-fieldset/_collapsible-fieldset.scss */
.collapsible-fieldset__legend,
.collapsible .fieldset-legend {
  display: block;
  padding-left: 15px;
  background-image: url(../sass/forms/collapsible-fieldset/expanded.svg);
  background-position: 4px 50%;
  background-repeat: no-repeat;
}

/* line 18, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/init/rtl/_rtl.scss */
[dir="rtl"] .collapsible-fieldset__legend,
[dir="rtl"] .collapsible .fieldset-legend,
.collapsible [dir="rtl"] .fieldset-legend {
  padding-left: 0;
  padding-right: 15px;
  background-position: right 4px top 50%;
}

/* line 29, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/forms/collapsible-fieldset/_collapsible-fieldset.scss */
.collapsible-fieldset__summary,
.collapsible .fieldset-legend .summary {
  color: #999999;
  font-size: .9em;
  margin-left: .5em;
}

/* line 35, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/forms/collapsible-fieldset/_collapsible-fieldset.scss */
.collapsible-fieldset.is-collapsed, .collapsible-fieldset--is-collapsed,
.is-collapsed.collapsible,
.collapsible.collapsed {
  border-bottom-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  height: 1em;
}

/* line 43, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/forms/collapsible-fieldset/_collapsible-fieldset.scss */
.collapsible-fieldset.is-collapsed .collapsible-fieldset__wrapper, .collapsible-fieldset--is-collapsed__wrapper, .is-collapsed.collapsible .collapsible-fieldset__wrapper,
.collapsible.collapsed .fieldset-wrapper {
  display: none;
}

/* line 48, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/forms/collapsible-fieldset/_collapsible-fieldset.scss */
.collapsible-fieldset.is-collapsed .collapsible-fieldset__legend, .collapsible-fieldset--is-collapsed__legend, .is-collapsed.collapsible .collapsible-fieldset__legend,
.collapsible.collapsed .fieldset-legend,
.collapsible-fieldset.is-collapsed .collapsible .fieldset-legend,
.collapsible .collapsible-fieldset.is-collapsed .fieldset-legend,
.is-collapsed.collapsible .fieldset-legend {
  background-image: url(../sass/forms/collapsible-fieldset/collapsed.svg);
  background-position: 4px 50%;
}

/* line 18, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/init/rtl/_rtl.scss */
[dir="rtl"] .collapsible-fieldset.is-collapsed .collapsible-fieldset__legend, [dir="rtl"] .collapsible-fieldset--is-collapsed__legend, [dir="rtl"] .is-collapsed.collapsible .collapsible-fieldset__legend,
[dir="rtl"] .collapsible.collapsed .fieldset-legend,
.collapsible.collapsed [dir="rtl"] .fieldset-legend,
[dir="rtl"] .collapsible-fieldset.is-collapsed .collapsible .fieldset-legend,
.collapsible [dir="rtl"] .collapsible-fieldset.is-collapsed .fieldset-legend,
[dir="rtl"] .is-collapsed.collapsible .fieldset-legend {
  background-image: url(../sass/forms/collapsible-fieldset/collapsed-rtl.svg);
  background-position: right 4px top 50%;
}

/* line 29, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/forms/form-item/_form-item.scss */
.form-item {
  margin: 1.42857rem 0;
}

/* line 34, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/forms/form-item/_form-item.scss */
.form-item__required,
.form-required {
  color: #c00;
}

/* line 39, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/forms/form-item/_form-item.scss */
.form-item__description,
.form-item .description {
  font-size: 1rem;
}

/* line 44, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/forms/form-item/_form-item.scss */
.form-item--inline div,
.form-item--inline label, .container-inline .form-item div, .container-inline .form-item label {
  display: inline;
}

/* line 50, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/forms/form-item/_form-item.scss */
.form-item--inline__exception,
.container-inline .fieldset-wrapper {
  display: block;
}

/* line 55, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/forms/form-item/_form-item.scss */
.form-item--tight,
.form-item--radio,
.form-type-radio,
.form-type-checkbox,
.password-parent,
.confirm-parent,
table .form-item {
  margin: 0;
}

/* line 63, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/forms/form-item/_form-item.scss */
.form-item--radio .form-item__label, .form-item--radio__label, .form-type-radio .form-item__label,
.form-type-checkbox .form-item__label,
label.option {
  display: inline;
  font-weight: normal;
}

/* line 69, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/forms/form-item/_form-item.scss */
.form-item--radio .form-item__description, .form-item--radio__description, .form-type-radio .form-item__description,
.form-type-checkbox .form-item__description,
.form-type-radio .description,
.form-type-checkbox .description,
.form-item--radio .form-item .description,
.form-item .form-item--radio .description,
.form-type-radio .form-item .description,
.form-item .form-type-radio .description,
.form-type-checkbox .form-item .description,
.form-item
.form-type-checkbox .description {
  margin-left: 1.4em;
}

/* line 74, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/forms/form-item/_form-item.scss */
.form-item.is-error .form-item__widget, .form-item--is-error__widget,
.form-item.is-error input,
.form-item.is-error textarea,
.form-item.is-error select,
.form-item--is-error input,
.form-item--is-error textarea,
.form-item--is-error select,
.form-item input.error,
.form-item textarea.error,
.form-item select.error {
  border: 1px solid #c00;
}

/* line 14, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/forms/form-table/_form-table.scss */
.form-table__sticky-header,
.sticky-header {
  position: fixed;
  visibility: hidden;
  margin-top: 0;
  background-color: #fff;
}

/* line 20, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/forms/form-table/_form-table.scss */
.form-table__sticky-header.is-sticky, .form-table__sticky-header--is-sticky,
.is-sticky.sticky-header {
  visibility: visible;
}

/* line 26, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/forms/form-table/_form-table.scss */
.form-table__header,
.form-table th,
form table th {
  border-bottom: 3px solid #cccccc;
  padding-right: 1em;
  text-align: left;
}

/* line 18, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/init/rtl/_rtl.scss */
[dir="rtl"] .form-table__header,
[dir="rtl"] .form-table th,
.form-table [dir="rtl"] th,
[dir="rtl"] form table th,
form table [dir="rtl"] th {
  text-align: right;
  padding-left: 1em;
  padding-right: 0;
}

/* line 38, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/forms/form-table/_form-table.scss */
.form-table__body,
.form-table tbody,
form table tbody {
  border-top: 1px solid #cccccc;
}

/* line 42, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/forms/form-table/_form-table.scss */
.form-table__row,
.form-table tbody tr,
form table tbody tr {
  padding: .1em .6em;
  border-bottom: 1px solid #cccccc;
  background-color: #eeeeee;
}

/* line 47, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/forms/form-table/_form-table.scss */
.form-table__row:nth-child(even),
.form-table tbody tr:nth-child(even),
form table tbody tr:nth-child(even) {
  background-color: #fff;
}

/* line 51, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/forms/form-table/_form-table.scss */
.form-table__row.is-active, .form-table__row--is-active,
.form-table tbody tr.is-active,
form table tbody tr.is-active,
td.active {
  background-color: #dddddd;
}

/* line 56, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/forms/form-table/_form-table.scss */
.form-table__row.is-disabled, .form-table__row--is-disabled,
.form-table tbody tr.is-disabled,
form table tbody tr.is-disabled,
td.menu-disabled {
  background: #cccccc;
}

/* line 61, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/forms/form-table/_form-table.scss */
.form-table__row.is-selected, .form-table__row--is-selected,
.form-table tbody tr.is-selected,
form table tbody tr.is-selected,
tr.selected td {
  background: #fffdf0;
}

/* line 67, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/forms/form-table/_form-table.scss */
.form-table__list,
.form-table ul,
form table ul {
  margin: 0;
}

/* line 71, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/forms/form-table/_form-table.scss */
.form-table__narrow-column,
.form-table th.form-table__narrow-column,
form table th.form-table__narrow-column,
td .checkbox,
th .checkbox {
  width: -moz-min-content;
  width: -webkit-min-content;
  text-align: center;
}

/* line 11, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/forms/progress-bar/_progress-bar.scss */
.progress-bar,
.progress {
  font-weight: bold;
}

/* line 15, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/forms/progress-bar/_progress-bar.scss */
.progress-bar__bar,
.progress .bar {
  border-radius: 3px;
  margin: 0 .2em;
  border: 1px solid #666666;
  background-color: #cccccc;
}

/* line 22, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/forms/progress-bar/_progress-bar.scss */
.progress-bar__fill,
.progress .filled {
  height: 1.5em;
  width: 5px;
  background: #0072b9 url(../sass/forms/progress-bar/progress-bar.gif) repeat 0 0;
}

/* line 28, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/forms/progress-bar/_progress-bar.scss */
.progress-bar__percentage,
.progress .percentage {
  float: right;
}

/* line 18, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/init/rtl/_rtl.scss */
[dir="rtl"] .progress-bar__percentage,
[dir="rtl"] .progress .percentage,
.progress [dir="rtl"] .percentage {
  float: left;
}

/* line 36, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/forms/progress-bar/_progress-bar.scss */
.progress-bar--inline,
.ajax-progress-bar {
  width: 16em;
  display: inline-block;
}

/* line 18, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/init/rtl/_rtl.scss */
[dir="rtl"] .progress-bar--inline,
[dir="rtl"] .ajax-progress-bar {
  float: right;
}

/* line 9, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/forms/progress-throbber/_progress-throbber.scss */
.progress-throbber,
.ajax-progress {
  display: inline-block;
}

/* line 18, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/init/rtl/_rtl.scss */
[dir="rtl"] .progress-throbber,
[dir="rtl"] .ajax-progress {
  float: right;
}

/* line 17, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/forms/progress-throbber/_progress-throbber.scss */
.progress-throbber__widget,
.ajax-progress .throbber {
  background: url(../sass/forms/progress-throbber/progress-throbber.gif) no-repeat 0 -18px transparent;
  float: left;
  height: 15px;
  margin: 2px;
  width: 15px;
}

/* line 18, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/init/rtl/_rtl.scss */
[dir="rtl"] .progress-throbber__widget,
[dir="rtl"] .ajax-progress .throbber,
.ajax-progress [dir="rtl"] .throbber {
  float: right;
}

/* line 29, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/forms/progress-throbber/_progress-throbber.scss */
.progress-throbber__widget-in-tr,
tr .ajax-progress .throbber {
  margin: 0 2px;
}

/* line 33, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/forms/progress-throbber/_progress-throbber.scss */
.progress-throbber__message,
.ajax-progress .message {
  padding-left: 20px;
}

/* line 9, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/forms/resizable-textarea/_resizable-textarea.scss */
.resizable-textarea,
.resizable-textarea textarea {
  width: 100%;
  vertical-align: bottom;
}

/* line 14, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/forms/resizable-textarea/_resizable-textarea.scss */
.resizable-textarea__grippie,
.resizable-textarea .grippie {
  background: url(../sass/forms/resizable-textarea/grippie.png) no-repeat center 2px #eeeeee;
  border: 1px solid #cccccc;
  border-top-width: 0;
  cursor: s-resize;
  height: 9px;
  overflow: hidden;
}

/* line 14, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/forms/table-drag/_table-drag.scss */
.table-drag__wrapper,
body.drag {
  cursor: move;
}

/* line 18, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/forms/table-drag/_table-drag.scss */
.table-drag__item,
tr.drag {
  background-color: #fffadb;
}

/* line 22, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/forms/table-drag/_table-drag.scss */
.table-drag__item-previous,
tr.drag-previous {
  background-color: #fff7c2;
}

/* line 26, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/forms/table-drag/_table-drag.scss */
.table-drag__handle,
.tabledrag-handle {
  cursor: move;
  float: left;
  height: 1.7em;
  margin-left: -1em;
  overflow: hidden;
  text-decoration: none;
  font-size: 12px;
}

/* line 18, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/init/rtl/_rtl.scss */
[dir="rtl"] .table-drag__handle,
[dir="rtl"] .tabledrag-handle {
  float: right;
  margin-right: -1em;
  margin-left: 0;
}

/* line 41, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/forms/table-drag/_table-drag.scss */
.table-drag__handle:focus, .table-drag__handle:hover,
.tabledrag-handle:focus,
.tabledrag-handle:hover {
  text-decoration: none;
}

/* line 47, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/forms/table-drag/_table-drag.scss */
.table-drag__handle-icon,
.tabledrag-handle .handle {
  box-sizing: content-box;
  background: url(../sass/forms/table-drag/handle-icon.png) no-repeat 6px 9px;
  height: 13px;
  margin: -.4em .5em;
  padding: .42em .5em;
  width: 13px;
}

/* line 56, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/forms/table-drag/_table-drag.scss */
.table-drag__handle.is-hover .table-drag__handle-icon, .table-drag__handle-icon--is-hover, .is-hover.tabledrag-handle .table-drag__handle-icon,
.tabledrag-handle-hover .handle,
.table-drag__handle.is-hover .tabledrag-handle .handle,
.tabledrag-handle .table-drag__handle.is-hover .handle,
.is-hover.tabledrag-handle .handle {
  background-position: 6px -11px;
}

/* line 61, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/forms/table-drag/_table-drag.scss */
.table-drag__toggle-weight-wrapper,
.tabledrag-toggle-weight-wrapper {
  text-align: right;
}

/* line 18, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/init/rtl/_rtl.scss */
[dir="rtl"] .table-drag__toggle-weight-wrapper,
[dir="rtl"] .tabledrag-toggle-weight-wrapper {
  text-align: left;
}

/* line 69, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/forms/table-drag/_table-drag.scss */
.table-drag__toggle-weight,
.tabledrag-toggle-weight {
  font-size: .9em;
}

/* line 73, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/forms/table-drag/_table-drag.scss */
.table-drag__indentation,
.indentation {
  float: left;
  height: 1.7em;
  margin: -.4em .2em -.4em -.4em;
  padding: .42em 0 .42em .6em;
  width: 20px;
}

/* line 18, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/init/rtl/_rtl.scss */
[dir="rtl"] .table-drag__indentation,
[dir="rtl"] .indentation {
  float: right;
  margin: -.4em -.4em -.4em .2em;
  padding: .42em .6em .42em 0;
}

/* line 87, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/forms/table-drag/_table-drag.scss */
.table-drag__tree-child,
.table-drag__tree-child-last,
.tree-child-last,
.table-drag__tree-child-horizontal,
.tree-child-horizontal,
.tree-child {
  background: url(../sass/forms/table-drag/tree-child.png) no-repeat 11px center;
}

/* line 18, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/init/rtl/_rtl.scss */
[dir="rtl"] .table-drag__tree-child,
[dir="rtl"] .table-drag__tree-child-last,
[dir="rtl"] .tree-child-last,
[dir="rtl"] .table-drag__tree-child-horizontal,
[dir="rtl"] .tree-child-horizontal,
[dir="rtl"] .tree-child {
  background-position: -65px center;
}

/* line 95, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/forms/table-drag/_table-drag.scss */
.table-drag__tree-child-last,
.tree-child-last {
  background-image: url(../sass/forms/table-drag/tree-child-last.png);
}

/* line 100, /Users/asiby/Projects/elMouhandiss/hanini/projects/websites/www/sgomali.com/www/sites/all/themes/sgomali/sass/forms/table-drag/_table-drag.scss */
.table-drag__tree-child-horizontal,
.tree-child-horizontal {
  background-position: -11px center;
}
