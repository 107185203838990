
.region-footer {

  background: #F9F9F9 url('../images/footer-shadow.png') no-repeat 0 0px;
  text-align: center;
  padding: 22px 0 54px 0;

  .block {

    &.last {
      margin-right: 0;
    }

    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    width: 270px;
    display: inline-block;
    margin-right: 26px;
    vertical-align: top;
    text-align: initial;
    padding: 11px 15px 15px 15px;
    color: #666666;
    margin-bottom: 30px;

    &#block-formblock-contact-site {
      input[type=text] {
        width: 100%;
      }
    }

    .form-actions {
      margin-top: 10px;
    }
  }
}

.region-bottom {
  background: #5b5b5b;
  opacity: 0.8;
  color: #fff;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: right;

  a {
    color: #fff;
  }

  p {
    margin: 0;
  }

  #block-block-3 {
    margin: auto 160px;

    ul {

      margin: 0;
      padding: 0;

      li {
        margin: 0 0 0 10px;
        display: inline-block;

        &:first-child {
          float: left;
          margin: 0;
        }
      }
    }

  }
}